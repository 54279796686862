<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>상품 결제 완료</span>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>구매 수량</dt>
                <dd>{{ result.pinList && result.pinList.length }}개</dd>
              </dl>
              <dl>
                <dt>총 결제금액</dt>
                <dd>
                  {{ $Util.formatNumber2(result.price, { unit: '원' }) }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>상품명</dt>
                <dd>{{ result.title }}</dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ buyerInfo.name }}</span>
                  <span>{{ buyerInfo.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(buyerInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(buyerInfo.loginType) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="!$Util.isEmpty(buyerInfo.login_type)"
                  >
                    {{
                      Number(buyerInfo.login_type) === 0
                        ? '카카오'
                        : Number(buyerInfo.login_type) === 1
                        ? '네이버'
                        : Number(buyerInfo.login_type) === 2
                        ? '구글'
                        : '애플'
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>총 결제액</dt>
                <dd>
                  <span class="sec_color">{{
                    $Util.formatNumber2(result.price, { unit: '' })
                  }}</span>
                  원<span
                    >&nbsp;&nbsp;({{ result.point }}P 사용 / 캐시 할인
                    {{ result.discount }}원)</span
                  >
                </dd>
              </dl>
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ result.paymentType }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ result.paymentDate }}</dd>
              </dl>
            </div>
          </div>
          <div v-if="market.type == '1'" class="lump">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                구매자 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="market.reqKakao == '1'" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        v-model="result.resKakao"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="market.reqPhone == '1'" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요"
                        v-model="result.resPhone"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="market.reqEmail == '1'" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        v-model="result.resEmail"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!--210713-->
          <div class="lump table_type02 bo_zero">
            <dl>
              <dt>구매내역</dt>
              <dd>
                <div class="form">
                  <div class="form_in">
                    <div v-if="computedPinList && computedPinList.length > 0">
                      <div
                        class="inline"
                        style="margin-top: 0.5rem"
                        v-for="(item, index) in computedPinList"
                        :key="index"
                      >
                        <label class="input">
                          <input :value="item.text" disabled />
                        </label>
                      </div>
                    </div>
                    <div class="hint">
                      마이페이지-마켓-구매내역에서 바코드 이미지를 확인하실 수
                      있습니다.
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnConfirm">확인</button>
            </div>
            <div class="btn_wrap" style="margin-bottom: unset;">
              <button class="btn bo" @click="fnWizzTalk">
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button class="btn bo" @click="fnMypage">바코드 보기</button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>유의사항</dt>
                <dd>
                  <div>
                    * 개별 공급자가 등록한 나눔 내용 및 거래에 대한 책임은
                    공급자가 부담하며, 이에 따라서 공급자가 진행하는 서비스에
                    대해서 위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 카드 결제 시 플랫폼 수수료에 대한 현금영수증이 결제자
                    휴대폰 번호로 자동 발행됩니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'market-done-index',
  setup() {
    return { ...componentState() }
  }
}

// https://localhost:8081/market/done?productNo=576&poid=WIZZNEY20210917QsbbH4-576-20211123133402-2000-2000
</script>

<style scoped></style>
