import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    productNo: route.query.productNo,
    poid: route.query.poid,
    result: {},
    buyerInfo: {},
    market: {},
    computedPinList: computed(
      () =>
        state.result.pinList &&
        state.result.pinList.map(el => {
          let text
          if (el.useDt) {
            text = `사용기한 ${proxy
              .$dayjs(el.useDt)
              .format('YYYY-MM-DD')} 까지`
          } else {
            text = '기한 없음'
          }
          return { ...el, text }
        })
    )
  })
  const fnResult = async () => {
    const params = route.query
    params.productType = proxy.$ConstCode.PRODUCT_TYPE.MARKET.value
    const res = await proxy.$CashSvc.postMarketPaymentResult(params)
    if (res.resultCode === '0000') {
      state.result = res
      state.buyerInfo = res.buyerInfo
    } else {
      alert(res.resultMsg)
    }
  }
  const fnCashReceipt = async () => {
    const params = {}
    params.poid = state.poid
    const res = await proxy.$CashSvc.postRequestCashReceipt(params)
    if (res.resultCode === '0000') {
      alert('현금영수증 신청되었습니다.')
    } else {
      alert(res.resultMsg)
    }
  }
  const fnModalAddInfo = async () => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_ADD_INFO,
      data: state.result,
      callback: async reply => {
        await fnSaveReqOption(reply)
      }
    }
    await fnModalAlert(payload)
  }
  const fnSaveReqOption = async reply => {
    const params = {}
    params.marketNo = state.productNo
    params.reply = reply
    params.memberSeq = state.result.memberSeq
    let res
    if (proxy.$Util.isEmpty(state.result.reply)) {
      res = await proxy.$MarketSvc.postMyPageBuyMarketOptions(params)
    } else {
      res = await proxy.$MarketSvc.postMyPageBuyMarketOptionsUpdate(params)
    }
    if (res.resultCode === '0000') {
      state.result.reply = reply
    } else {
      if (res.resultMsg === '이미 등록이 되어 있습니다.') {
        res = await proxy.$MarketSvc.postMyPageBuyMarketOptionsUpdate(params)
        if (res.resultCode === '0000') {
          state.result.reply = reply
        }
      } else {
        alert(res.resultMsg)
      }
    }
  }
  const fnWizzTalk = async () => {
    const params = {}
    params.marketNo = state.result.marketNo
    params.memberSeq = state.result.memberSeq
    const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
    if (res.resultCode === '0000') {
      params.chatroomId = res.chatroomId
      await router.push({
        path: '/market/wizz-talk',
        query: params
      })
    } else {
      alert(res.resultMsg)
    }
  }

  const fnConfirm = async () => {
    if (
      state.result.optionYn === 'Y' &&
      proxy.$Util.isEmpty(state.result.reply)
    ) {
      if (
        !confirm(
          '판매자가 요청한 정보 입력을 하지 않았습니다. 다음에 하시겠습니까? 정보 입력은 마이페이지> 마켓 내역> 구매에서 다시 입력할 수 있습니다.'
        )
      )
        return
      await router.push({ path: '/market/list' })
    } else {
      await router.push('/market/list')
    }
  }

  const fnMypage = async () => {
    const query = {
      marketNo: state.result.marketNo,
      memberSeq: state.result.memberSeq
    }
    await router.push({
      name: 'my-page-market-buyer-view-index',
      query
    })
  }

  const fnMarketDetail = async () => {
    const res = await proxy.$MarketSvc.postMarketDetail({
      marketNo: route.query.productNo
    })
    if (res.resultCode === '0000') {
      state.market = res.detail
    } else {
      alert(res.resultMsg)
    }
  }

  const init = async () => {
    await fnResult()
    await fnMarketDetail()
  }
  init()
  return {
    ...toRefs(state),
    fnCashReceipt,
    fnWizzTalk,
    fnModalAddInfo,
    fnConfirm,
    fnMypage
  }
}
